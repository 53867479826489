<template>
  <RouterView />
  <div v-if="store.isLoading" class="loader-overlay">
    <div class="loader">
      <div class="loader-border"></div>
      <svg
        version="1.1"
        id="L7"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 100 100"
        xml:space="preserve"
      >
        <path
          fill="#0077B5"
          d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
  c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="2s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
        <path
          fill="#0084ff"
          d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
  c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="-360 50 50"
            repeatCount="indefinite"
          />
        </path>
        <path
          fill="#1565C0"
          d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
  L82,35.7z"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="2s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  </div>


  <div v-if="store.viewComment" class="relative ">
    <Comments @close-dialog="view = false" :open-dialog="view" />
  </div>

  <!-- <div v-if="store.viewComment && !view" class="relative ">
    <div class="absolute bottom-10 right-6 bg-transparent ">
      <button type="button" class=" bg-transparent example-1 cursor-pointer rounded-full font-bold flex items-center"
        @click="view = true">
        <div class="icon-content cursor-pointer bg-transparent">
          <div class="link cursor-pointer">
            <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" enable-background="new 0 0 64 64"
              xml:space="preserve" fill="#000000">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path fill="#fff"
                  d="M60,0H4C1.789,0,0,1.789,0,4v40c0,2.211,1.789,4,4,4h8v15c0,0.404,0.243,0.77,0.617,0.924 C12.741,63.976,12.871,64,13,64c0.26,0,0.516-0.102,0.707-0.293L29.414,48H60c2.211,0,4-1.789,4-4V4C64,1.789,62.211,0,60,0z M15,14 h16c0.553,0,1,0.447,1,1s-0.447,1-1,1H15c-0.553,0-1-0.447-1-1S14.447,14,15,14z M45,34H15c-0.553,0-1-0.447-1-1s0.447-1,1-1h30 c0.553,0,1,0.447,1,1S45.553,34,45,34z M14,27c0-0.553,0.447-1,1-1h24c0.553,0,1,0.447,1,1s-0.447,1-1,1H15 C14.447,28,14,27.553,14,27z M49,22H15c-0.553,0-1-0.447-1-1s0.447-1,1-1h34c0.553,0,1,0.447,1,1S49.553,22,49,22z">
                </path>
              </g>
            </svg>
          </div>
          <div class="tooltip">Comment</div>
        </div>
      </button>
    </div>
  </div> -->

</template>
<script setup>
import { onMounted, ref } from 'vue';
import { RouterView } from "vue-router";
import { useWerisStore } from "@/stores/index.js";
import Comments from './components/shared/comments.vue';
import useIdleTimeout from '@/hooks/useIdleTimeout';


const store = useWerisStore();
const emits = defineEmits(["closeView"]);

const view = ref(false);
useIdleTimeout();

</script>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  /* Adds blur effect */
  z-index: 1000;
}

.loader {
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--primary);
  animation: spin 2s linear infinite;
}

.loader-svg {
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}

.comment-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1000;
}

.comment {
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--primary);
  animation: spin 2s linear infinite;
}

/* From Uiverse.io by PriyanshuGupta28 */
ul {
  list-style: none;
}

.example-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.example-1 .icon-content {
  margin: 0 10px;
  position: relative;
}

.example-1 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #E1EFFE;
  color: #1760B2;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}

.example-1 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}

.example-1 .icon-content .link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  color: #4d4d4d;
  background-color: #1760B2;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
}

.example-1 .icon-content .send {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #4d4d4d;
  background-color: #1760B2;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
}

.example-1 .icon-content .cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #4d4d4d;
  background-color: #ee1d1d;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
}

.example-1 .icon-content .link svg {
  width: 30px;
  height: 30px;
}

.example-1 .icon-content .link[data-social="telegram"]:hover {
  color: #0088cc;
}

/* From Uiverse.io by andrew-demchenk0 */
.card {
  --main-color: #000;
  --bg-color: #EBD18D;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 300px;
  padding: 25px;
  background: var(--bg-color);
  border-radius: 20px;
}

.card__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card___wrapper-acounts {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}

.card___wrapper-acounts>div:nth-child(2) {
  position: absolute;
  left: 25px;
  z-index: -1;
}

.card___wrapper-acounts>div:nth-child(3) {
  position: absolute;
  left: 50px;
  z-index: -2;
}

.card__score {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: var(--main-color);
}

.card__acounts {
  width: 42px;
  height: 42px;
}

.card__acounts svg {
  width: 100%;
  height: 100%;
}


.card__title {
  margin-top: 50px;
  font-weight: 900;
  font-size: 25px;
  color: var(--main-color);
}

.card__subtitle {
  margin-top: 15px;
  font-weight: 400;
  font-size: 15px;
  color: var(--main-color);
}

.card__indicator {
  margin-top: 50px;
  font-weight: 500;
  font-size: 14px;
  color: var(--main-color);
}

.card__progress progress {
  width: 100%;
  height: 4px;
  border-radius: 100px;
}

.card__progress progress::-webkit-progress-bar {
  background-color: #00000030;
  border-radius: 100px;
}

.card__progress progress::-webkit-progress-value {
  background-color: var(--main-color);
  border-radius: 100px;
}
</style>
