class RSAEncryption {
  constructor(publicKeyPEM, privateKeyPEM) {
    this.publicKey = null;
    this.keyLoadedTime = null;
    this.maxKeyAge = 2000; // 2 seconds in milliseconds
    this.publicKeyPEM = publicKeyPEM;
    this.privateKeyPEM = privateKeyPEM;
  }

  async loadKey() {
    try {
      // Remove PEM headers and convert to binary
      const pemHeader = "-----BEGIN PUBLIC KEY-----";
      const pemFooter = "-----END PUBLIC KEY-----";
      const pemContents = this.publicKeyPEM
        .replace(pemHeader, "")
        .replace(pemFooter, "")
        .replace(/\s/g, "");

      const binaryDer = window.atob(pemContents);
      const arrayBuffer = new Uint8Array(binaryDer.length);
      for (let i = 0; i < binaryDer.length; i++) {
        arrayBuffer[i] = binaryDer.charCodeAt(i);
      }

      // Import the key
      this.publicKey = await window.crypto.subtle.importKey(
        "spki",
        arrayBuffer,
        {
          name: "RSA-OAEP",
          hash: { name: "SHA-512" },
        },
        true,
        ["encrypt"]
      );

      this.keyLoadedTime = Date.now();
    } catch (e) {
      throw new Error(`Key loading failed: ${e.message}`);
    }
  }

  async checkKeyFreshness() {
    if (
      !this.keyLoadedTime ||
      Date.now() - this.keyLoadedTime > this.maxKeyAge
    ) {
      await this.loadKey();
    }
  }

  async encrypt(message, addTimestamp = true) {
    console.log(message, "Message to encrypt")
    await this.checkKeyFreshness();

    try {
      // Prepare the data with timestamp and nonce if required
      const dataToEncrypt = addTimestamp
        ? {
            message,
            timestamp: new Date().toISOString(),
            nonce: Array.from(crypto.getRandomValues(new Uint8Array(16)))
              .map((b) => b.toString(16).padStart(2, "0"))
              .join(""),
          }
        : message;

      // Convert data to string
      const jsonData = JSON.stringify(dataToEncrypt);
      const encoder = new TextEncoder();
      const data = encoder.encode(jsonData);

      // Encrypt the data
      const encrypted = await window.crypto.subtle.encrypt(
        {
          name: "RSA-OAEP",
        },
        this.publicKey,
        data
      );

      // Convert to Base64 for transmission
      return btoa(String.fromCharCode(...new Uint8Array(encrypted)));
    } catch (e) {
      throw new Error(`Encryption failed: ${e.message}`);
    }
  }
}

const encryptionService = new RSAEncryption(
  import.meta.env.VITE_PUBLIC_KEY,
  import.meta.env.VITE_PRIVATE_KEY
);

export default encryptionService;
