// src/authService.js
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { StorageService } from "../services/storageService.js";
import encryptionService from "../services/encryptionService.js";
import { getUserProfileAndRole } from "../graphql/settings/user.graphql.js";
import { notifyError, notifySuccess } from "../services/notificationService.js";
import { apolloClient } from "../apollo.js";
import router from "../router/index.js";

const oidcConfig = {
  authority: 'https://ubunifuv3.all.co.tz/oauth2',
  client_id: import.meta.env.VITE_SSO_CLIENT_ID,
  redirect_uri: 'https://weris.all.tz/auth/ubunifu-sso/callback',
  response_type: 'code',
  scope: 'openid email',
  state: Math.random().toString(36).substr(2),  // Add a manual state parameter
  post_logout_redirect_uri: 'https://weris.all.tz',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  loadUserInfo: true
};


const userManager = new UserManager(oidcConfig);

export default {
  async login() {
    try {
      // Generate a random state value for security
      const state = Math.random().toString(36).substring(2);
      const client_id = import.meta.env.VITE_SSO_CLIENT_ID;
      // Manually redirect to the authorization endpoint
      const url = `${import.meta.env.VITE_SSO_AUTHORITY}` +
        `?client_id=${client_id}` +
        `&redirect_uri=${encodeURIComponent(`${import.meta.env.VITE_SSO_REDIRECT_URI}`)}` +
        '&response_type=code' +
        '&scope=openid profile email' +
        `&state=${state}`;  // Include the generated state in the URL

      // Store the state in localStorage or sessionStorage to validate later
      localStorage.setItem('auth_state', state);

      // Redirect the user to the authorization URL
      window.location.href = url;
    } catch (err) {
      console.error('Error during login:', err);
    }
  },

  async handleCallback() {
    try {
      console.log('Handling callback...');
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const returnedState = urlParams.get('state');

      // Get the previously stored state
      const storedState = localStorage.getItem('auth_state');

      if (storedState !== returnedState) {
        throw new Error('State mismatch. Potential CSRF detected.');
      }

      // Prepare to exchange the authorization code for tokens
      const tokenResponse = await this.exchangeCodeForToken(code);
      console.log('Token Response:', tokenResponse);

      // Now that you have the access token, you can use it to fetch user details
      const userInfo = await this.getUserInfo(tokenResponse.access_token);
      console.log('User Info:', userInfo);

      const sendUserInfo = await this.sendUserInfo(userInfo);
      console.log('Send User Info:', sendUserInfo);

      // Clear the state from storage after successful validation
      localStorage.removeItem('auth_state');
      // Proceed with your app logic (e.g., redirect to dashboard)
    } catch (err) {
      console.error('Error during callback handling:', err);
    }
  },

  async sendUserInfo(userInfo) {
    console.log(userInfo, "User Info", JSON.stringify(userInfo))
    const encrytedInfo = await encryptionService.encrypt(JSON.stringify(userInfo.profile));
    console.log(encrytedInfo, "Encrypted info")
    try {
      // if (!this.encryptor.publicKey) {
      //   await this.encryptor.loadKey();
      // }
      const response = await fetch(`${import.meta.env.VITE_APP_WERIS_SERVER}oauth/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_data: encrytedInfo,
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        let storageService = new StorageService()
        storageService.setItem('access_token', responseData.access_token);
        storageService.setItem('refresh_token', responseData.refresh_token);
        storageService.setItem('expires_in', responseData.expires_in);

        let expireTime = new Date()
        expireTime.setSeconds(expireTime.getSeconds() + responseData.expires_in)
        storageService.setItem('expireTime', expireTime.getTime())

        notifySuccess("Successful login");
        // router.push('/admin')

        await this.loadUserProfile('login')
      } else {we
        const errorDetails = await response.text(); // Get additional details about the failure
        throw new Error(`Failed to send user info: ${errorDetails}`);
      }
    } catch (error) {
      console.error('Error sending user info:', error);
      throw error; // Re-throw to propagate error handling further
    }
  },

  async exchangeCodeForToken(code) {
    try {
      const url = `${import.meta.env.VITE_SSO_TOKEN}`;
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };
      const body = new URLSearchParams({
        grant_type: 'authorization_code',
        code,
        redirect_uri: `${import.meta.env.VITE_SSO_REDIRECT_URI}`,
        client_id: import.meta.env.VITE_SSO_CLIENT_ID,
        // Uncomment and add client_secret if needed
        client_secret: import.meta.env.VITE_SSO_CLIENT_SECRET,
      });

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      });

      if (!response.ok) {
        const errorDetails = await response.text(); // Get additional details about the failure
        throw new Error(`Failed to exchange code for token: ${errorDetails}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error exchanging code for token:', error);
      throw error; // Re-throw to propagate error handling further
    }
  },

  async logout() {
    try {
      await userManager.signoutRedirect();
    } catch (err) {
      console.error('Error during logout:', err);
    }
  },

  async getUserInfo(accessToken) {
    const response = await fetch(`${import.meta.env.VITE_SSO_SERVER}/userinfo`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user info');
    }

    return await response.json();
  },


  async getUser() {
    try {
      return await userManager.getUser();
    } catch (err) {
      console.error('Error fetching user:', err);
      return null;
    }
  },

  async loadUserProfile(from = 'other') {
    let storageService = new StorageService();

    return await apolloClient.query({
      fetchPolicy: "no-cache",
      query: getUserProfileAndRole
    }).then(async (response) => {
      let result = Object.values(response)[0];
      result = Object.values(result)[0];
      if (result?.response?.status) {
        // console.log(result?.data?.length)
        let userProfile = result.data ? result.data : {}
        storageService.removeItem('userProfile')
        storageService.setItem('userProfile', userProfile)
        // console.log(storageService.getItem('userProfile'))
      } else {
        notifyError(`${result.response.code}: ${result.response.message}`);

      }
      return result?.response?.status;
    }).finally(async () => {
      this.isLoading = false;
    });
  },
};
