<template>
  <v-dialog v-model="dialog" :persistent="true" class="w-50">
    <div class="card intro-x justify-center items-center font-poppins">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="card-inner p-5 text-white">
        <button
          type="button"
          class="flex first-letter:px-3 z-2 float-right mt-[-1rem] mr-[-1rem]"
          @click="closeDialog"
        >
          <span class="font-bold text-mg text-white"
            ><img src="@/assets/images/bold.svg" alt=""
          /></span>
        </button>
        <div class="w-full flex justify-center">
          <img src="@/assets/images/Logo.svg" class="h-20 w-20" alt="" />
        </div>
        <h1 class="text-center jura font-extrabold mb-4 text-2xl">
          Welcome Back
        </h1>
        <button @click="logout()" v-if="sso_user">Logout</button>
        <button type="submit" class="w-full mt-2 cursor-pointer rounded-lg border border-primary bg-primary p-4 font-medium text-white transition hover:bg-opacity-90" @click="sso_login()">Login With Ubunifu</button>

        <div class="my-4 flex items-center space-x-4">
          <div class="w-1/2 border-b-4"></div>
          <div class="text-center jura text-black font-extrabold text-2xl"> OR </div>
          <div class="w-1/2 border-b-4 "></div>
        </div>
        <!-- <div v-if="user">
          <p>Welcome, {{ sso_user.profile.name }}</p>
          <p>Email: {{ sso_user.profile.email }}</p>
        </div> -->
        <form class="text-black mt-4" @submit.prevent="login()">
          <div class="mb-4">
            <div class="relative">
              <input
                type="text"
                v-model="user.username"
                id="emailAddress"
                placeholder="Username / Email"
                class="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />

              <span class="absolute right-4 top-4">
                <svg
                  class="fill-current"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      d="M19.2516 3.30005H2.75156C1.58281 3.30005 0.585938 4.26255 0.585938 5.46567V16.6032C0.585938 17.7719 1.54844 18.7688 2.75156 18.7688H19.2516C20.4203 18.7688 21.4172 17.8063 21.4172 16.6032V5.4313C21.4172 4.26255 20.4203 3.30005 19.2516 3.30005ZM19.2516 4.84692C19.2859 4.84692 19.3203 4.84692 19.3547 4.84692L11.0016 10.2094L2.64844 4.84692C2.68281 4.84692 2.71719 4.84692 2.75156 4.84692H19.2516ZM19.2516 17.1532H2.75156C2.40781 17.1532 2.13281 16.8782 2.13281 16.5344V6.35942L10.1766 11.5157C10.4172 11.6875 10.6922 11.7563 10.9672 11.7563C11.2422 11.7563 11.5172 11.6875 11.7578 11.5157L19.8016 6.35942V16.5688C19.8703 16.9125 19.5953 17.1532 19.2516 17.1532Z"
                      fill=""
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>

          <div class="mt-6">
            <div class="relative">
              <input
                type="password"
                v-model="user.password"
                id="password"
                placeholder="password"
                class="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />

              <span class="absolute right-4 top-4">
                <svg
                  class="fill-current"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                      fill=""
                    />
                    <path
                      d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                      fill=""
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>

          <div class="mt-6 flex justify-center">
            <input
              type="submit"
              value="Sign In"
              class="cursor-pointer rounded-lg border border-primary bg-primary py-2 px-4 font-medium text-white transition hover:bg-opacity-90"
            />
          </div>

        
        </form> 
        
      </div>
    </div>
  </v-dialog>
</template>
<script setup>
import { useWerisStore } from "@/stores";
import { useRouter } from "vue-router";
import { ref, watch,onMounted } from "vue";
import authService from "@/services/authService.js";

const user = ref({
  username: "",
  password: "",
});

const store = useWerisStore();
const router = useRouter();

const login = () => {
  store.login(user.value);
};
// Define user as a reactive variable
const sso_user = ref(null);

// Function to handle login
const sso_login = () => {
  authService.login();
};

// Function to handle logout
const logout = () => {
  authService.logout();
};

// Lifecycle hook to fetch the authenticated user on component mount
onMounted(() => {
  authService.getUser().then((authUser) => {
    if (authUser) {
      user.value = authUser; // Update the reactive variable with the user data
    }
  });
});
const props = defineProps(["openDialog"]);
const emits = defineEmits(["closeDialog"]);

const dialog = ref(false);

watch(
  () => props.openDialog,
  (value) => {
    dialog.value = value;
  }
);

const closeDialog = () => {
  emits("closeDialog");
};
</script>

<style scoped>
.btn {
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: all 0.2s ease-in;
  color: white;
  position: relative;
  z-index: 1;
}

h1 {
  color: #1760b2;
}
.card {
  height: 550px;
  transition: all 0.2s;
  position: center;
  cursor: pointer;
  background-color: #ffffff;
}

.card-inner {
  width: inherit;
  height: inherit;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 8px;
}

.card-inner input {
  border: 1px solid white;
}
.card:hover {
  transform: scale(1.04) rotate(1deg);
}

.circle {
  width: 100px;
  height: 100px;
  background: radial-gradient(#1760b2, #4a83c5fd);
  border-radius: 50%;
  position: absolute;
  animation: move-up6 2s ease-in infinite alternate-reverse;
}

.circle:nth-child(1) {
  top: -25px;
  left: -25px;
}

.circle:nth-child(2) {
  bottom: -25px;
  right: -25px;
  animation-name: move-down1;
}

@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}
</style>
