<template>
    <div class="relative">
      <div
        class="absolute bottom-10 right-6 p-2 bg-gray-200 shadow h-[750px] w-[400px] rounded-lg">
        <div class="relative flex flex-col justify-between h-full">
          
          <div class="">
            <div class="bg-primary py-4 flex flex-col justify-center items-center">
              <div class="flex items-center grid grid-cols-3 gap-3 w-full rounded-lg space-x-2 ">
                <div class="flex justify-end col-span-2 ">
                  <div class="flex items-center space-x-2 rounded-lg bg-[#89ABD2] py-2 px-4">
                    <div class="h-5 w-5">
                      <svg viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                        d="M17 1.5v7.969c0 0.827-0.673 1.5-1.5 1.5h-0.508v-1h0.508c0.276 0 0.5-0.225 0.5-0.5v-7.969c0-0.275-0.224-0.5-0.5-0.5h-14c-0.276 0-0.5 0.225-0.5 0.5v7.969c0 0.275 0.224 0.5 0.5 0.5h1.5v2.892l1.779-1.569 0.661 0.75-3.44 3.035v-4.108h-0.5c-0.827 0-1.5-0.673-1.5-1.5v-7.969c0-0.827 0.673-1.5 1.5-1.5h14c0.827 0 1.5 0.673 1.5 1.5zM14.020 9.54v3.043c0 0.827-0.673 1.5-1.5 1.5h-0.5v3.064l-3.667-3.064h-0.853c-0.827 0-1.5-0.673-1.5-1.5v-3.043c0-0.827 0.673-1.5 1.5-1.5h5.020c0.827 0 1.5 0.673 1.5 1.5zM13.020 9.54c0-0.275-0.224-0.5-0.5-0.5h-5.020c-0.276 0-0.5 0.225-0.5 0.5v3.043c0 0.275 0.224 0.5 0.5 0.5h1.217l2.303 1.926v-1.926h1.5c0.276 0 0.5-0.225 0.5-0.5v-3.043z"
                        fill="#000000"></path>
                      </g>
                    </svg>
                  </div>
                  <div>Comments</div>
                </div>
              </div>
              <div class="flex justify-end mr-2">
                <div class="bg-red rounded-full p-2 cursor-pointer" @click="store.viewComment = false">
                  <svg width="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z"
                      fill="#fff"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex justify-center mt-2 px-2 items-center"><p class="">Topic: <span class="">{{ store.selectedProjectActivity.name }}</span></p></div>
            
            </div>
            <div class="mt-2 overflow-y-scroll h-[450px]" :class="{ 'h-[600px]': selectedText }">
              <div class="" v-for="(msg, index) in messages">
                
                <div class="flex items-center space-x-2" @mouseenter="hoveredReply = index"
                @mouseleave="hoveredReply = null">
                <div class="flex flex-col bg-[#89ABD2] p-2 rounded-lg w-3/4 mb-2 " >
                  <div class="text-sm text-gray-800 flex justify-between"><span>{{msg?.projectCommentSendBy?.userFirstName}}</span> <span >{{msg?.createdAt}}</span></div>
                    <div class="">{{msg?.projectComment}}</div>
                    <div class="font text-gray-700 flex w-full justify-end text-xs"><span class="ml-4">{{ msg.time }}</span></div>
                  </div>
                  <div @click="reply(msg)" v-if="hoveredReply === index" class=""><svg-icon name="reply" class="h-5 w-5 mr-2 cursor-pointer" color="white"></svg-icon></div>
                </div>
                <div v-if="msg?.response?.length" class="flex justify-end flex-col mb-2 pr-2">
                  <div class="grid grid-cols-4 gap-4 ">
                    <div v-for="(reply, index) in msg?.response " class="col-end-5 col-span-3 mb-2 p-2 rounded-lg " style="background-color: #0571208a;">
                    <div class="text-sm text-gray-800 flex justify-between"><span>{{reply?.projectCommentRepliedBy?.userFirstName}}</span> 
                      <!-- <span >{{reply?.projectCommentResponseTime}}</span> -->
                    </div>
                    <div class="">{{reply?.projectCommentResponse}}</div>
                    <div class="font text-gray-700 flex w-full justify-end text-xs"><span class="ml-4">{{reply?.time}}</span></div>
                  </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div  class="absolute bg-white bottom-0 w-full">
            <div v-if="isAdmin &&  !selectedText" class="relative">
              <QuillEditor v-model:content="comment" style="background-color: #f8fafc; height: 100px" class="w-full"
                placeholder="Write your comment here." theme="snow" />
              <div class="absolute top-2 right-0">
                <div v-if="comment" class="">
                  <button type="button" class=" bg-transparent example-1 cursor-pointer rounded-full font-bold flex items-center"
                      @click="sendComment">
                      <div class="icon-content cursor-pointer bg-transparent">
                        <div class="send cursor-pointer">
                          <svg width="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M18.455 9.8834L7.063 4.1434C6.76535 3.96928 6.40109 3.95274 6.08888 4.09916C5.77667 4.24558 5.55647 4.53621 5.5 4.8764C5.5039 4.98942 5.53114 5.10041 5.58 5.2024L7.749 10.4424C7.85786 10.7903 7.91711 11.1519 7.925 11.5164C7.91714 11.8809 7.85789 12.2425 7.749 12.5904L5.58 17.8304C5.53114 17.9324 5.5039 18.0434 5.5 18.1564C5.55687 18.4961 5.77703 18.7862 6.0889 18.9323C6.40078 19.0785 6.76456 19.062 7.062 18.8884L18.455 13.1484C19.0903 12.8533 19.4967 12.2164 19.4967 11.5159C19.4967 10.8154 19.0903 10.1785 18.455 9.8834V9.8834Z"
                                stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </button>
                </div>
              </div>
            </div>

            <div v-if="selectedText" class="bg-[#F8FAFC] relative">
              <div class="flex space-x-4 items-center py-2 mx-2">
                <div class="flex flex-col border-l-4 border-gray-200 pt-2 px-2 overflow-y-scroll  w-full h-[50px]" >
                  {{selectedText?.projectComment}}
                </div>
                <div @click="selectedText = null" class="cursor-pointer">
                  <svg-icon name="close" class="h-5 w-5" color="red"></svg-icon>
                </div>
              </div>
              <div class="border relative">
                <QuillEditor v-model:content="comment" style="background-color: #E5E7EB; height: 60px" class="w-full"
                placeholder="Write your comment here." theme="" />
              </div>
              <div class="absolute bottom-0 right-0">
                <div v-if="comment" class="">
                  <button type="button" class=" bg-transparent example-1 cursor-pointer rounded-full font-bold flex items-center"
                      @click="sendComment">
                      <div class="icon-content cursor-pointer bg-transparent">
                        <div class="send cursor-pointer">
                          <svg width="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M18.455 9.8834L7.063 4.1434C6.76535 3.96928 6.40109 3.95274 6.08888 4.09916C5.77667 4.24558 5.55647 4.53621 5.5 4.8764C5.5039 4.98942 5.53114 5.10041 5.58 5.2024L7.749 10.4424C7.85786 10.7903 7.91711 11.1519 7.925 11.5164C7.91714 11.8809 7.85789 12.2425 7.749 12.5904L5.58 17.8304C5.53114 17.9324 5.5039 18.0434 5.5 18.1564C5.55687 18.4961 5.77703 18.7862 6.0889 18.9323C6.40078 19.0785 6.76456 19.062 7.062 18.8884L18.455 13.1484C19.0903 12.8533 19.4967 12.2164 19.4967 11.5159C19.4967 10.8154 19.0903 10.1785 18.455 9.8834V9.8834Z"
                                stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </button>
                </div>
              </div>
            </div>

            
          </div>

        </div>  
      </div>
    </div>
   
    

  </template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRoute } from "vue-router";
import { useWerisStore } from "@/stores/index.js";
import { QuillEditor } from "@vueup/vue-quill";
import {createProjectActivityCommentMutation, getProjectPersonalReportComment, createProjectPersonalReportCommentResponseMutation} from "@/graphql/pdp/pdp.graphql"
import { storeToRefs } from "pinia";
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { StorageService } from "@/services/storageService.js";

const store = useWerisStore();
const route = useRoute();
const view = ref(false);
const { getStoreItem } = storeToRefs(store);

const comment = ref("")

const props = defineProps(["openDialog", "selectedData"]);
const emits = defineEmits(["closeDialog"]);
const user = new StorageService().getItem("userProfile");
const isAdmin =
  user &&
  ["INSTITUTION_ADMIN", "SUPER_ADMIN"].includes(user.userProfile.profileType);


const messages = computed (() => {
  return getStoreItem.value('personalActivityComment').map(msg => {
    return{
      ...msg,
      time: msg.projectCommentTime.substring(0, 5),
      response: msg?.projectCommentResponse.map(response => {
        return{
          ...response,
          time: response.projectCommentResponseTime.substring(0, 5),
        }
      })
    }
  })
})

const hoveredReply = ref(null)
const selectedText = ref(null)

const reply = (msg) => {
  selectedText.value = msg
}

const sendComment = () => {
  const comment_value = comment.value.ops[0].insert;
  
  if (!selectedText.value ){
    store.createStoreItem({
      entity: "personalActivityComment",
      input: {
        profile: route?.params?.userId,
        project: store.selectedProjectActivity.uniqueId,
        projectComment: comment_value
      // project: id,
      },
      mutation: createProjectActivityCommentMutation,
    });
  }
  else {
    store.createStoreItem({
      entity: "personalActivityComment",
      input: {
        projectComment: selectedText?.value?.uniqueId,
        projectCommentResponse: comment_value
      },
      mutation: createProjectPersonalReportCommentResponseMutation,
    });
  }

    
  selectedText.value = null
  comment.value = null;
  emits('closeDialog', true)
  // store.viewComment = false
  store.selectedProjectActivity = null;
};

onMounted ( () => {
  store.loadStoreItem({
    entity: "personalActivityComment",
    query: getProjectPersonalReportComment,
    filtering: {
      pageNumber: 1,
      project: store.selectedProjectActivity.uniqueId,
    },
  });

})

  </script>
  
  <style scoped>
  .comment-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    z-index: 1000;
  }
  
  .comment {
    position: relative;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .comment-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid var(--primary);
    animation: spin 2s linear infinite;
  }
  
  /* From Uiverse.io by PriyanshuGupta28 */
  ul {
    list-style: none;
  }
  
  .example-1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .example-1 .icon-content {
    margin: 0 10px;
    position: relative;
  }
  
  .example-1 .icon-content .tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #E1EFFE;
    color: #1760B2;
    padding: 6px 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  
  .example-1 .icon-content:hover .tooltip {
    opacity: 1;
    visibility: visible;
    top: -50px;
  }
  
  .example-1 .icon-content .link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    color: #4d4d4d;
    background-color: #1760B2;
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
  }
  
  .example-1 .icon-content .send {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: #4d4d4d;
    background-color: #1760B2;
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
  }
  
  .example-1 .icon-content .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: #4d4d4d;
    background-color: #ee1d1d;
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
  }
  
  .example-1 .icon-content .link svg {
    width: 30px;
    height: 30px;
  }
  
  .example-1 .icon-content .link[data-social="telegram"]:hover {
    color: #0088cc;
  }
  
  /* From Uiverse.io by andrew-demchenk0 */
  .card {
    --main-color: #000;
    --bg-color: #EBD18D;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 300px;
    padding: 25px;
    background: var(--bg-color);
    border-radius: 20px;
  }
  
  .card__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .card___wrapper-acounts {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
    cursor: pointer;
  }
  
  .card___wrapper-acounts>div:nth-child(2) {
    position: absolute;
    left: 25px;
    z-index: -1;
  }
  
  .card___wrapper-acounts>div:nth-child(3) {
    position: absolute;
    left: 50px;
    z-index: -2;
  }
  
  .card__score {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--main-color);
  }
  
  .card__acounts {
    width: 42px;
    height: 42px;
  }
  
  .card__acounts svg {
    width: 100%;
    height: 100%;
  }
  
  
  .card__title {
    margin-top: 50px;
    font-weight: 900;
    font-size: 25px;
    color: var(--main-color);
  }
  
  .card__subtitle {
    margin-top: 15px;
    font-weight: 400;
    font-size: 15px;
    color: var(--main-color);
  }
  
  .card__indicator {
    margin-top: 50px;
    font-weight: 500;
    font-size: 14px;
    color: var(--main-color);
  }
  
  .card__progress progress {
    width: 100%;
    height: 4px;
    border-radius: 100px;
  }
  
  .card__progress progress::-webkit-progress-bar {
    background-color: #00000030;
    border-radius: 100px;
  }
  
  .card__progress progress::-webkit-progress-value {
    background-color: var(--main-color);
    border-radius: 100px;
  }
  </style>
  