import axios from "axios";
import {defineStore} from "pinia";
import qs from 'qs';
import {apolloClient} from "../apollo.js";
import {changePasswordMutation, forgotPasswordMutation,createPasswordMutation} from "../graphql/authentication.graphql.js";
import {getUserProfileAndRole} from "../graphql/settings/user.graphql.js";
import router from "../router/index.js";
import {notifyError, notifySuccess} from "../services/notificationService.js";
import {StorageService} from "../services/storageService.js";

export const useWerisStore = defineStore('WerisStore', {
  state: () => {
    return {
      isLoading: false,
      viewComment: false,
      isFileLoading: false,
      documentLink: null,
      openDocumentViewer: false,
      documentTitle: null,
      selectedProjectActivity: null,
      state: {
        myProfile: {},
        pagination: {},
        userRoles: [],
        userProfile: [],
        memberProfile: [],
        userprojects: [],
        selectedProject: {},
        users: [],
        vulnerabilities: [],
        firstDashboard: {},
        systemPermissions: [],
        changePassword: [],
        forgotPassword: [],
        designation: [],
        activateUser: [],
        departments: [],
        units: [],
        sections: [],
        expertise: [],
        pdps: [],
        skillsData: [],
        skillsCategoriesData: [],
        projects: [],
        projectCategory: [],
        projectMembers: [],
        projectStakeholder: [],
        projectComment: [],
        pdpUsefulLinks: [],
        pdpAttachments: [],
        pdpApplication: [],
        pdpRemarks: [],
        pdpRemarkResponse: [],
        assignments: [],
        assignmentMember: [],
        assignmentsAttachments: [],
        deleteProjectMembers: [],
        projectReporting: [],
        assignmentReporting: [],
        assignmentReportingActivities: [],
        role: [],
        securityAssesments: [],
        activitiesSummary: [],
        thisWeekActivities: [],
        nextWeekActivities: [],
        dashboard: [],
        pdpChallenge: [],
        projectBlockingIssues: [],
        projectSummary: [],
        departmentUnit: [],
        sectionUser: [],
        generalProjectReport: [],
        specialTask: [],
        specialTaskAttachments: [],
        specialTaskReporting: [],
        specialTaskReportingActivities: [],
        securityTrend: [],
        securityAssessment: [],
        user: [],
        securityTrendCategory: [],
        topContibutors: [],
        topContributedProjects: [],
        unreportedProjects: [],
        cybersecurityAndForensic: [],
        hackFuAndTechniques: [],
        projectType: [],
        personalActivityComment: [],
        personalActivityCommentResponse: [],
        generalProjectReportStas: [],
        securityDashboardReportStas: [],
        specialTaskReportByActivityData: [],
        weeklyReportEmail: [],
        projectPagination: {}
      }
    }
  },

  getters: {
    getStoreItem: (state) => {
      return (entity) => state.state[entity]
    }
  },


  actions: {

    async login(user) {
      // router.push('/dashboard')

      this.isLoading = true
      let url = `${import.meta.env.VITE_APP_WERIS_SERVER}oauth2/access_token`

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          client_id: import.meta.env.VITE_APP_CLIENT_ID,
          client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
          grant_type: 'password',
          username: user.username,
          password: user.password
        }),
        url,
      };
      axios(options).then(async (response) => {
        if (response.status === 200) {
          let storageService = new StorageService()
          storageService.setItem('access_token', response.data.access_token);
          storageService.setItem('refresh_token', response.data.refresh_token);
          storageService.setItem('expires_in', response.data.expires_in);

          let expireTime = new Date()
          expireTime.setSeconds(expireTime.getSeconds() + response.data.expires_in)
          storageService.setItem('expireTime', expireTime.getTime())

          notifySuccess("Successful login");
          // router.push('/admin')

          await this.loadUserProfile('login')

        }
      }).catch(async (error) => {
        if (error) {
          if (error?.response?.data?.error === "invalid_grant") {
            await notifyError("Sorry ! Wrong Credentials Provided")
          }
        }
      }).finally(async () => {
        this.isLoading = false
      });
    },

    async changePassword(oldPassword, newPassword) {
      this.isLoading = true;
      return await apolloClient.mutate({
        fetchPolicy: "no-cache",
        mutation: changePasswordMutation,
        variables: {
          input: {
            oldPassword: oldPassword,
            newPassword: newPassword
          }
        }
      }).then(async (response) => {
        let result = Object.values(response)[0];
        result = Object.values(result)[0];
        if (result?.response?.status) {
          notifySuccess(result.response.message);
          new StorageService().clearStorage()
          // await router.push('/home')
        } else {
          notifyError(`${result.response.code}: ${result.response.message}`);
        }
        return result?.response?.status;
      }).finally(async () => {
        this.isLoading = false;
      });
    },
    async createPassword(password, confirmPassword) {
      this.isLoading = true;
      return await apolloClient.mutate({
        fetchPolicy: "no-cache",
        mutation: createPasswordMutation,
        variables: {
          input: {
            password:  password,
            confirmPassword: confirmPassword
          }, 
        }
      }).then(async (response) => {
        let result = Object.values(response)[0];
        result = Object.values(result)[0];
        if (result?.response?.status) {
          notifySuccess(result.response.message);
          new StorageService().clearStorage()
        } else {
          notifyError(`${result.response.code}: ${result.response.message}`);
        }
        return result?.response?.status;
      }).finally(async () => {
        this.isLoading = false;
      });
    },


    async forgotPassword(userEmail) {
      this.isLoading = true;

      try {
        const response = await apolloClient.mutate({
          fetchPolicy: "no-cache",
          mutation: forgotPasswordMutation,
          variables: {
            userEmail: userEmail
          }
        });

        const result = response?.data?.forgotPasswordMutation?.response;

        if (result?.status) {
          notifySuccess(result.message);
          new StorageService().clearStorage();
          await router.push('/home');
        } else {
          notifyError(`${result.code}: ${result.message}`);
        }

        return result?.status;
      } catch (error) {
        // Handle network or other errors
        console.error("Error in forgotPassword:", error);
        notifyError("An error occurred while processing your request.");
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    async loadUserProfile(from = 'other') {
      let storageService = new StorageService();

      return await apolloClient.query({
        fetchPolicy: "no-cache",
        query: getUserProfileAndRole
      }).then(async (response) => {
        let result = Object.values(response)[0];
        result = Object.values(result)[0];
        if (result?.response?.status) {
          let userProfile = result.data ? result.data : {}
          storageService.removeItem('userProfile')
          storageService.setItem('userProfile', userProfile)
          router.push('/admin')
        } else {
          notifyError(`${result.response.code}: ${result.response.message}`);

        }
        return result?.response?.status;
      }).finally(async () => {
        this.isLoading = false;
      });
    },

    changeStoreItemState(item) {
      let { entity, id, data, remove } = item;
      try {
        if (Array.isArray(this.state[entity])) {
          let index = this.state[entity].findIndex((el) => el?.id === id);
          if (index !== -1) {
            if (remove) {
              this.state[entity].splice(index, 1);
            } else {
              this.state[entity][index] = data;
            }
          } else {
            if (!Array.isArray(data)) this.state[entity].unshift(data);
            else {
              for (const datum of data) {
                this.state[entity].unshift(datum);
              }
            }
          }
          this.state[entity] = [...this.state[entity]];
        }
      } catch (e) {
        console.log(e);
      }
    },

    async createStoreItem(item) {
      let { entity, input, mutation, isInput } = item;
      this.isLoading = true;
      return await apolloClient.mutate({
        fetchPolicy: "no-cache",
        mutation,
        variables: (Object.keys(input).length === 1) && isInput ? input : { input }
      }).then(async (response) => {

        let result = Object.values(response)[0];
        result = Object.values(result)[0];
        if (result?.response?.status) {
          notifySuccess(result.response.message);
          if (result?.data && entity?.length)
            this.changeStoreItemState({
              entity,
              data: result.data
            });
        } else {
          notifyError(`${result.response.code}: ${result.response.message}`);
        }
        return result?.response?.status;
      }).finally(async () => {
        this.isLoading = false;
      });
    },
    async uploadFile(file) {
      this.isFileLoading = true
      const formData = new FormData();
      formData.append("file", file);
      const storageService = new StorageService();
      const accessToken = storageService.getItem('access_token');
      try {
        const response = await axios.post(`${import.meta.env.VITE_APP_WERIS_SERVER}file/upload/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`, // Example: Get token from storage service
          },
        });

        console.log('Upload successful:', response.data);
        await notifySuccess('File Uploaded Successfully')
        return response.data;
        // Handle success, e.g., show a success message or update UI
      } catch (error) {
        console.error('Error uploading file:', error);
        await notifyError('Error uploading file')
        // Handle error, e.g., show an error message or retry logic
      } finally {
        this.isFileLoading = false;
      }
    },
    async updateStoreItem(item) {
      let { entity, input, isInput, mutation } = item;
      delete input.id;
      this.isLoading = true
      return await apolloClient.mutate({
        fetchPolicy: "no-cache",
        mutation,
        variables: (Object.keys(input).length === 1) && isInput ? input : { input }
      }).then(async (response) => {
        let result = Object.values(response)[0];
        result = Object.values(result)[0];
        if (result?.response?.status) {
          notifySuccess(result.response.message);
          if (result?.data && entity?.length)
            this.changeStoreItemState({
              entity,
              id: result.data.id,
              data: result.data
            });
        } else {
          notifyError(`${result.response.code}: ${result.response.message}`);
        }
        return result?.response?.status;
      }).finally(async () => {
        this.isLoading = false
      });
    },
    async removeStoreItem(item) {
      let { entity, id } = item;
      this.changeStoreItemState({
        entity,
        id: id,
        remove: true
      });
    },
    async deleteStoreItem(item) {
      let { entity, id, input, isInput, mutation } = item;
      this.isLoading = true
      return await apolloClient.mutate({
        fetchPolicy: "no-cache",
        mutation,
        variables: isInput ? { input } : input
      }).then(async (response) => {
        let result = Object.values(response)[0];
        result = Object.values(result)[0];
        if (result?.response?.status) {
          notifySuccess(result.response.message);
          this.changeStoreItemState({
            entity,
            id: id,
            remove: true
          });
        } else {
          notifyError(`${result.response.code}: ${result.response.message}`);
        }
        return result?.response?.status;
      }).finally(async () => {
        this.isLoading = false
      });
    },

    async loadStoreItem(item) {
      let {entity, filtering, query, isInput, dontStorePagination, paginationEntity} = item;
      console.log(item)
      this.isLoading = true
      this.state[entity] = []
      return await apolloClient.query({
        fetchPolicy: "no-cache",
        query: query,
        variables: isInput ? filtering : { filtering }
      }).then(async (response) => {
        let result = Object.values(response)[0];
        result = Object.values(result)[0];
        if (Object.hasOwn(result, "page")) {
          if (!dontStorePagination) {
            console.log(paginationEntity, 'project Pagination')
            if (paginationEntity) {
              this.state[paginationEntity] = result?.page
            } else {
              this.state.pagination = result?.page
            }
          }
        } else {
          this.state.pagination = {}
        }
        if (result.response.status) {
          this.state[entity] = result?.data
        } else {
          notifyError(`${result.response.code}: ${result.response.message}`);
        }
        return result.data;
      }).finally(async () => {
        this.isLoading = false
      });
    }
  }
})


