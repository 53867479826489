<template>
  <div
    class="absolute z-20 top-0 left-0 right-0 shadow-md h-[86px] md:bg-gradient-to-b md:from-slate-100 md:to-transparent"
  ></div>
  <div
    class="absolute z-30 top-2 left-8 right-8 shadow-sm rounded-xl h-10 bg-[#98c3e4]"
  ></div>
  <div
    class="flex absolute z-40 top-4 left-2 right-2 justify-end items-center drop-shadow-sm bg-[#1760b2] shadow-md rounded-lg"
  >
    <div
      class="flex flex-grow items-center justify-between px-4 py-3 shadow-2 md:px-6 2xl:px-11"
    >
      <div class="cursor-pointer flex">
        <div class="text-center">
          <v-dialog
            width="auto"
            scrollable
            transition="dialog-left-transition"
            fullscreen
          >
            <template v-slot:activator="{ props: activatorProps }">
              <svg-icon
                v-bind="activatorProps"
                name="menu"
                class="h-7 w-7 md:hidden"
                color="rgb(2 132 199)"
              ></svg-icon>
            </template>

            <template v-slot:default="{ isActive }">
              <v-card class="bg-[#1972bf] w-60">
                <v-card-title class="d-flex justify-space-between align-center">
                  <div></div>
                  <svg-icon
                    @click="isActive.value = false"
                    name="close"
                    class="h-7 w-7 md:hidden"
                    color="white"
                  ></svg-icon>
                </v-card-title>
                <v-card-text class="px-4">
                  <template
                    v-for="(link, index) in navigationLinks"
                    :key="index"
                  >
                    <ul v-for="childLink in link?.children">
                      <li
                        class="mt-2 text-white"
                        @click="isActive.value = false"
                      >
                        <router-link
                          router-link
                          :to="childLink?.link"
                          class="flex"
                        >
                          <svg-icon
                            :name="childLink?.icon"
                            color="white"
                            class="h-8 w-8 mr-2"
                          ></svg-icon>
                          <div class="font-bold text-md mt-1">
                            {{ childLink?.name }}
                          </div>
                        </router-link>
                      </li>
                    </ul>
                  </template>
                </v-card-text>

                <v-divider></v-divider>
              </v-card>
            </template>
          </v-dialog>
        </div>
        <p @click="router.push('/')" class="text-white font-bold text-2xl">WERIS</p>
      </div>

      <div class="flex items-center">
        <div class="bg-white p-1 rounded-full mr-3">
          <svg-icon
            @click="isNotificationVisible = !isNotificationVisible"
            name="notification"
            class="h-5 w-5 cursor-pointer"
            color="rgb(2 132 199)"
          ></svg-icon>
          <div
              v-if="isNotificationVisible"
              ref="myDiv"
              class="absolute right-0 slide-in-left z-40 h-screen top-0 w-1/4 bg-white rounded-lg p-3 overflow-y-auto no-scrollbar"
          >
            <notification :type="type" @close="isNotificationVisible = false" />
          </div>
          <span
            class="absolute top-7 -z-1 inline-flex w-2 h-2 animate-ping rounded-full bg-red-500 opacity-75"
          ></span>
        </div>
<!--        <div class="bg-sky-50 p-1 rounded-full mr-3">-->
<!--          <svg-icon-->
<!--            @click="toggleDiv"-->
<!--            name="message"-->
<!--            class="h-5 w-5 cursor-pointer"-->
<!--            color="rgb(2 132 199)"-->
<!--          ></svg-icon>-->
<!--          <div v-if="isVisible" ref="myDivs" class="absolute right-0 slide-in-left z-40 h-screen top-0 w-1/4 bg-stone-100 p-3 overflow-y-auto no-scrollbar">-->
<!--            <comments-notification :type="type" @close="isVisible = false" />-->
<!--          </div>-->
<!--          <span-->
<!--            class="absolute top-7 -z-1 inline-flex w-2 h-2 animate-ping rounded-full bg-red-500 opacity-75"-->
<!--          ></span>-->
<!--        </div>-->
        <div class="flex items-center relative">
         
          <span
            class="hidden cursor-pointer text-right lg:block"
            @click="toggleDropdown"
          >
            <span class="block text-sm font-medium text-white">
              {{ user?.userProfile?.userFirstName }}
              {{ user?.userProfile?.userLastName }}
            </span>
            <span class="block text-xs text-white font-medium">
              {{ user?.userProfile?.userEmail }}
            </span>
          </span>
          <div
            class="rounded-full ml-3 flex justify-center items-center h-10 w-10 bg-gray-200 cursor-pointer"
            @click="toggleDropdown"
          >
            <img
              :src="imagePreview(user?.userProfile?.profilePhoto)"
              class="w-full h-auto rounded-full object-cover"
            />
          </div>
          <div
            v-if="isActive"
            class="dropdown z-50 rounded-md bg-white drop-shadow-md p-3 w-44"
          >
            <div
              class="mb-2 flex w-full z-50 justify-center"
              v-for="item in logoutMenu"
            >
              <span
                class="hover:bg-sky-600 rounded-md cursor-pointer p-2 hover:text-white w-full"
                v-if="item?.slug === 'logout'"
                @click="logout"
                >{{ item?.title }}</span
              >

              <router-link
                v-else
                :to="`/${item?.slug}`"
                class="hover:bg-sky-600 rounded-md p-2 hover:text-white w-full"
                :key="item.slug"
                @click="handleMenuItemClick(item)"
              >
                {{ item?.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="flex items-center">
      <div class="bg-sky-50 p-2 rounded-full mr-3">
        <svg-icon name="notification" class="h-7 w-7" color="rgb(2 132 199)"></svg-icon>
      </div>

      <div class="bg-sky-50 p-2 rounded-full mr-3">
         <v-badge color="error" dot>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9687 0.575012H7.03123C3.43123 0.575012 0.506226 3.41564 0.506226 6.90314C0.506226 10.3906 2.75623 12.8375 8.26873 15.3125C8.40935 15.3688 8.52185 15.3969 8.66248 15.3969C8.85935 15.3969 9.0281 15.3406 9.19685 15.2281C9.4781 15.0594 9.64685 14.75 9.64685 14.4125V13.2031H10.9687C14.5687 13.2031 17.5219 10.3625 17.5219 6.87501C17.5219 3.38751 14.5687 0.575012 10.9687 0.575012ZM10.9687 11.9938H9.33748C8.8031 11.9938 8.3531 12.4438 8.3531 12.9781V14.0188C3.59998 11.825 1.74373 9.80001 1.74373 6.93126C1.74373 4.14689 4.10623 1.86876 7.03123 1.86876H10.9687C13.8656 1.86876 16.2562 4.14689 16.2562 6.93126C16.2562 9.71564 13.8656 11.9938 10.9687 11.9938Z" fill="#64748B"/>
            <path d="M5.42812 6.28439C5.0625 6.28439 4.78125 6.56564 4.78125 6.93127C4.78125 7.29689 5.0625 7.57814 5.42812 7.57814C5.79375 7.57814 6.075 7.29689 6.075 6.93127C6.075 6.56564 5.79375 6.28439 5.42812 6.28439Z" fill="#64748B"/>
            <path d="M9.00002 6.28439C8.6344 6.28439 8.35315 6.56564 8.35315 6.93127C8.35315 7.29689 8.6344 7.57814 9.00002 7.57814C9.33752 7.57814 9.6469 7.29689 9.6469 6.93127C9.6469 6.56564 9.33752 6.28439 9.00002 6.28439Z" fill="#64748B"/>
            <path d="M12.5718 6.28439C12.2062 6.28439 11.9249 6.56564 11.9249 6.93127C11.9249 7.29689 12.2062 7.57814 12.5718 7.57814C12.9374 7.57814 13.2187 7.29689 13.2187 6.93127C13.2187 6.56564 12.9093 6.28439 12.5718 6.28439Z" fill="#64748B"/>
            </svg>
         </v-badge> 
        </div>

      <div class="flex items-center relative">
        <p class="bg-sky-50 rounded-2xl text-primary p-2">Welcome, {{user?.userProfile?.userEmail}}</p>
        <div class="rounded-full ml-3 flex justify-center items-center h-10 w-10 bg-gray-200 cursor-pointer"
          @click="toggleDropdown">
          <svg-icon name="profile" class="h-5 w-5" color="black" stroke="lack"></svg-icon>
        </div>
        <div v-if="isActive" class="dropdown z-10 rounded-md bg-white drop-shadow-md p-3">
          <div class="mb-2" v-for="item in logoutMenu">
             {{ item?.slug }} 
            <span class="hover:bg-sky-600 rounded-md cursor-pointer p-2 hover:text-white" v-if="item?.slug === 'logout'"
              @click="logout">{{ item?.title }}</span>
  
            <router-link v-else :to="`/${item?.slug}`" class="hover:bg-sky-600 rounded-md p-2 hover:text-white"
              :key="item.slug" @click="handleMenuItemClick(item)">
  
              {{ item?.title }}
            </router-link>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { StorageService } from "@/services/storageService.js";
import { ref } from "vue";
import { useRouter } from "vue-router";

import SvgIcon from "../shared/SvgIcon.vue";
import notification from "@/components/notification/Notification.vue";
import CommentsNotification from "@/components/notification/CommentsNotification.vue";

const props = defineProps(["navigationLinks", "type", "ctype"]);

const user = new StorageService().getItem("userProfile");
const router = useRouter();
const isActive = ref(false);
const dialog = ref("");

const viewDialog = () => {
  openDialog.value = true;
};

const openDialog = ref(false);
const imagePreview = (profilePhoto) => {
  return profilePhoto
    ? `${import.meta.env.VITE_APP_WERIS_FILE_SERVER}${profilePhoto}`
    : new URL("@/assets/images/account.png", import.meta.url).href;
};
const toggleDropdown = () => {
  isActive.value = !isActive.value;
};
const isVisible = ref(false);
const isNotificationVisible = ref(false)
const myDiv = ref(null);
const myDivs = ref(null);

const toggleDiv = () => {
  isVisible.value = !isVisible.value;
};

const logoutMenu = ref([
  {
    title: "My Profile",
    slug: "settings/profile",
  },

  {
    title: "Logout",
    slug: "logout",
  },
]);

const notificationMenu = ref([
  {
    title: "My Profile",
    slug: "settings/profile",
  },
  {
    title: "Change Password",
    slug: "change_password",
  },
  {
    title: "Logout",
    slug: "logout",
  },
]);

const logout = () => {
  let storageService = new StorageService();
  storageService.clearStorage();
  router.push("/");
};

const handleMenuItemClick = (item) => {
  // Handle menu item click, for example, close the dropdown
  isActive.value = false;

  // You can add more logic here based on the clicked item
  if (item.slug === "logout") {
    logout();
  }
};
</script>

<style scoped>
.dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  right: 0;
  top: 100%;
  /* Adjust the top value to position the dropdown below the trigger button */
}
</style>
