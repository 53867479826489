import SettingsHome from "@/views/settings/SettingsHome.vue";


export default [
    {
        path: "/settings",
        redirect: "/settings/home",
        // component: SettingsHome,
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('@/views/settings/SettingsHome.vue')
            },
            {
                path: 'role-settings/:roleUid',
                name: 'rolesAssignment',
                component: () => import('@/views/settings/roles-settings/PermissionAssignment.vue')
            },
            {
                path: 'designation-settings',
                name: 'designationSetting',
                component: () => import('@/views/settings/designation-settings/DesignationList.vue')

            },
            {
                path: 'role-settings',
                name: 'rolesSettings',
                component: () => import('@/views/settings/roles-settings/ListRoles.vue')

            },
            {
                path: 'assign-designation',
                name: 'assigndesignationSetting',
                component: () => import('@/views/settings/assign-designation/AssignDesignation.vue')

            },
            {
                path: 'profile',
                name: 'profile',
                component: () => import('@/views/profile/profileView.vue')
            },
            {
                path: 'createPassword',
                name: 'createPassword',
                component: () => import('@/views/authentication/CreatePassword.vue')
            },
            {
                path: 'edit-profile',
                name: 'editprofile',
                component: () => import('@/views/profile/EditProfile.vue')
            },
            {
                path: 'project-categories',
                name: 'projectcategories',
                component: () => import('@/views/settings/project-categories/CategoriesList.vue')

            }, 
            {
                path: 'technologies-skills',
                name: 'projecttechnologies',
                component: () => import('@/views/settings/skills/SkillsList.vue')

            }, 
            {
                path: 'skill-categories',
                name: 'skillcategories',
                component: () => import('@/views/settings/skillsCategories/SkillsCategoryList.vue')

            },
            {
                path: 'department-unit-settings',
                name: 'institutionsettings',
                component: () => import('@/views/settings/department-unit-settings/DeptUnitDetails.vue')
            },
            {
                path: 'setting-institutions',
                name: 'settingInstitutions',
                component: () => import('@/views/settings/setting-institutions/InstitutionList.vue')
            },
            {
                path: 'directorate-detail/:uid',
                name: 'directorate',
                component: () => import('@/views/settings/department-unit-settings/DeptSectionDetails.vue')
            },
            {
                path: 'unit-detail/:uid',
                name: 'unit',
                component: () => import('@/views/settings/department-unit-settings/UnitsDetails.vue')
            },
            {
                path: 'section-detail/:uid',
                name: 'section',
                component: () => import('@/views/settings/department-unit-settings/SectionUserDetails.vue')
            },
            {
                path: 'user-management',
                name: 'usermanagement',
                component: () => import('@/views/settings/user-management/UserList.vue')
            },
            {
                path: 'user-details',
                name: 'userdetails',
                component: () => import('@/views/settings/user-management/UserDetails.vue')
            },
            {
                path: 'create-template',
                name: 'createtemplate',
                component: () => import('@/views/settings/template-configuration/template/CreateTemplate.vue')
            },
            {
                path: 'user-profile/:uid',
                name: 'userprofile',
                component: () => import('@/views/settings/user-management/UserDetails.vue')
            },
            {
                path: 'sub-categories/:uid',
                name: 'subcategories',
                component: () => import('@/views/settings/project-categories/SubcategoryListPerCategory.vue')
            },
            {
                path:'security-vulnerabilities',
                name:'vulnerabilities',
                component: () => import('@/views/settings/securityVulnerabilities/VulnerabilityList.vue')
            }, 
            {
                path:'area-of-expertise',
                name:'areaOfExpertise',
                component: () => import('@/views/settings/areaOfExpertise/AreaOfExpertiseList.vue')
            },

            {
                path:'project-type',
                name:'projectType',
                component: () => import('@/views/settings/project-categories/ProjectTypeList.vue')
            },
            {
                path: 'weeklyReport-settings',
                name: 'weeklyReportSetting',
                component: () => import('@/views/settings/weeklyReport-settings/WeeklyReportEmailList.vue')

            },
        ]
    },
];
